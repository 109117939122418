import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { PublicRoutes, PrivateRoutes } from "./RoutePath";
const NotFoundPage = lazy(() => import("@pages/NotFound"));

const RoutesComponent = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {PublicRoutes.map((publicRouteObj) => (
          <Route
            key={publicRouteObj.path}
            element={<PublicRoute restricted={publicRouteObj.restricted} />}
          >
            <Route
              path={publicRouteObj.path}
              element={publicRouteObj.element}
            />
          </Route>
        ))}
        {PrivateRoutes.map((privateRouteObj) => (
          <Route key={privateRouteObj.path} element={<PrivateRoute />}>
            <Route
              key={privateRouteObj.path}
              path={privateRouteObj.path}
              element={privateRouteObj.element}
            />
          </Route>
        ))}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponent;
