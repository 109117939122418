import { Box, Icon, Link, Text } from "@pingux/astro";
import OpenInNewIcon from "@pingux/mdi-react/OpenInNewIcon";
import { aboutCardStyle } from "./aboutCardStyles";

export type AboutCardProps = {
  developer: string;
  developerHref?: string;
  supportHref: string;
  companyWebsite: string;
  aboutText?: string;
};

const AboutCardLinkWithIcon = ({
  href,
  label,
}: {
  href: string;
  label: string;
}) => (
  <Box isRow gap="sm">
    <Link href={href}>{label}</Link>
    <Icon
      color="primary"
      icon={OpenInNewIcon}
      size="sm"
      title={{ name: "Open In New Icon" }}
    />
  </Box>
);

const AboutCard = ({
  developer,
  companyWebsite,
  supportHref,
  developerHref,
  aboutText,
}: AboutCardProps) => {
  return (
    <Box sx={aboutCardStyle}>
      <Text variant="h4">About the Developer</Text>
      <Box gap="sm">
        <Link sx={{ fontWeight: 2 }} href={developerHref ? developerHref : ""}>
          {developer}
        </Link>
        <Text lineHeight="1.5">{aboutText ? aboutText : ""}</Text>
      </Box>
      <AboutCardLinkWithIcon href={supportHref} label="Support" />
      <AboutCardLinkWithIcon href={companyWebsite} label="Visit Website" />
    </Box>
  );
};

export default AboutCard;
