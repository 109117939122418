import React, { ReactNode } from "react";
import { Box, CodeView, Grid, Text } from "@pingux/astro";

// import { codeViewText } from "../../../shared/mockData/codeViewText";
// import CategoriesWidget from "../../shared/CategoriesWidget/CategoriesWidget";

import AboutCard, { AboutCardProps } from "@components/AboutCard/AboutCard";
// import FeaturesList from "./FeaturesList";
import VersionCard, {
  VersionCardProps,
} from "@components/VersionCard/VersionCard";

const leftColumnStyles = {
  paragraph: {
    lineHeight: "28px",
  },
};

interface ResourcePageTemplateProps {
  developerProps: AboutCardProps;
  categories: string[];
  features: string[];
  versionProps: VersionCardProps;
  description: string;
  enablementDescription: string;
  slots?: {
    topSlot?: ReactNode;
    bottomSlot?: ReactNode;
  };
}

const ResourcePageTemplate = (props: ResourcePageTemplateProps) => {
  const {
    developerProps,
    categories,
    features,
    versionProps,
    description,
    enablementDescription,
    slots,
  } = props;

  const bottomSlot = slots?.bottomSlot;
  const topSlot = slots?.topSlot;
  return (
    <Box maxWidth="100%" gap="lg">
      {topSlot && topSlot}
      <Grid
        columns={["100%", "100%", "100%", "auto 360px", "auto 360px"]}
        gap="lg"
      >
        <Box gap="lg">
          <Box>
            <Text sx={leftColumnStyles.paragraph}>{description}</Text>
          </Box>
          {/* <FeaturesList features={features} /> */}
          <Box gap="sm">
            {/* <Text variant="h4">How to Enable</Text>
            <Text sx={leftColumnStyles.paragraph}>{enablementDescription}</Text> */}
          </Box>
          {/* <CodeView language="jsx" sx={{ width: "100%", height: 300 }}>
            {codeViewText}
          </CodeView> */}
          {/* {bottomSlot && bottomSlot} */}
          {/* <CategoriesWidget categories={categories} /> */}
        </Box>
        <Box gap="xl">
          <AboutCard {...developerProps} />
          <VersionCard {...versionProps} />
        </Box>
      </Grid>
    </Box>
  );
};

export default ResourcePageTemplate;
